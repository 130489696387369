import {User} from '@ion/core/src/types';
import {get} from '@ion/core/src/environment';
import {error, info} from '@ion/core/src/logger';
import {client} from '@ion/core/src/services/ConnectUtils';
import {publicConfig} from '@/store/configuration';
/**
 * @goToDevPortal
 *
 * Prepares auth token and redirects CTC user to DEV portal
 */
export const goToDevPortal = (data) => {
  return async dispatch => {
    try {
      if (data.token) {
        const url = `${publicConfig.devPortalUrl}?auth_token=${data.token}`;
        window.location.href = url;
      } else {
        throw 'Failed to Navigate to dev portal!';
      }
    } catch (err) {
      error('Failed to navigate to dev portal!');
      error(err);
      throw err;
    }
  };
};

export const signinIntoDevPortal = async(username: string, password: string, privateDocs:boolean): Promise<any> => {
  const signResult = await client.post('/signin', {
    username,
    password,
    privateDocs
  }, {
    method  : 'post',
    baseURL : publicConfig.signInUrl
  });
  return signResult;
};
